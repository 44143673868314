@font-face {
  font-family: 'Black';
  src: url('/assets/font/black.ttf') format('truetype');
}

.top-bar, .deadliest-topbar, .deadliest-index-links, .deadliest-font {
  font-family: 'Black';
}

.deadliest-white {
  background: $white;
}

.deadliest-index-welcome-block {
  height: 180px;
}

.deadliest-adsense-block {
  height: 100px;
}

a.deadliest-topbar {
  color: $primary-color;
}  

a.deadliest-index-links {
  color: $deadliest-ocean-dark;
}

a:hover.deadliest-topbar {
  color: $deadliest-ocean-spray;
}  

a:hover.deadliest-index-links {
  color: $deadliest-ocean-black;
}  

td.deadliest-table-cell-col-border {
  border-left: 1px solid $medium-gray;
}

.footer-grid {
  height: 80px;
}

.footer {
  color: $deadliest-ocean-spray;
}
