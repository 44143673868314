// $social-button-size: 3.125rem;
// $social-button-border-width: 0.125rem;
// $social-button-font-size: 1.5625rem;
// $social-button-line-height: 2em;
// $social-button-border-radius: 1.6875rem;
// $social-button-transition: all 0.5s ease;
// $social-button-margin: 0.25rem;

$social-button-size: 2.500rem;
$social-button-border-width: 0.125rem;
$social-button-font-size: 1.2000rem;
$social-button-line-height: 2em;
$social-button-border-radius: 1.6875rem;
$social-button-transition: all 0.5s ease;
$social-button-margin: 0.25rem;

// Source: https://designpieces.com/2012/12/social-media-colours-hex-and-rgb/
$social-brand-facebook: #3b5998;
$social-brand-twitter: #55acee;
$social-brand-linkedin: #007bb5;
$social-brand-youtube: #bb0000;
$social-brand-instagram: #125688;
$social-brand-pinterest: #cb2027;
$social-brand-google-plus: #dd4b39;
$social-brand-github: #000000;
$social-brand-tumblr: #32506d;

@mixin social-button($brand-color, $brand-icon) {
  background: $brand-color;

  &:before {
    font-family: "FontAwesome";
    content: $brand-icon;
  }
  &:hover,
  &:focus {
    color: $brand-color;
    background: $white;
    border-color: $brand-color;
  }
}

.rounded-social-buttons {
  text-align: center;

  .social-button {
    display: inline-block;
    position: relative;
    cursor: pointer;
    width: $social-button-size;
    height: $social-button-size;
    border: $social-button-border-width solid transparent;
    padding: 0;
    text-decoration: none;
    text-align: center;
    color: $white;
    font-size: $social-button-font-size;
    font-weight: normal;
    line-height: $social-button-line-height;
    border-radius: $social-button-border-radius;
    transition: $social-button-transition;
    margin-right: $social-button-margin;
    margin-bottom: $social-button-margin;

    &:hover,
    &:focus {
      transform: rotate(360deg);
    }

    &.facebook {
      @include social-button($social-brand-facebook, "\f09a")
    }

    &.twitter {
      @include social-button($social-brand-twitter, "\f099")
    }

    &.linkedin {
      @include social-button($social-brand-linkedin, "\f0e1")
    }

    &.youtube {
      @include social-button($social-brand-youtube, "\f167")
    }

    &.instagram {
      @include social-button($social-brand-instagram, "\f16d")
    }

    &.pinterest {
      @include social-button($social-brand-pinterest, "\f231")
    }

    &.google-plus {
      @include social-button($social-brand-google-plus, "\f0d5")
    }

    &.github {
      @include social-button($social-brand-github, "\f09b")
    }

    &.tumblr {
      @include social-button($social-brand-tumblr, "\f173")
    }
  }
}
